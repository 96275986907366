import React from "react";
import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";

const Terms = () => {
  return (
    <div className="w-[60%] mx-auto">
      <section>
        <h1 className="text-primary text-2xl  font-medium py-10">
          Ofu K'Idoma Crowd Funding Platform - Terms and Conditions
        </h1>
        <p className="text-primary py-4">
          By accessing or using the Ofu K'Idoma crowd funding platform, you
          agree to be bound by these Terms and Conditions. The platform,
          provided by Ofu K'Idoma for Unity and Development Association,
          connects project creators in the Idoma community with donors
          worldwide. You are responsible for maintaining the confidentiality of
          your account credentials and for all activities under your account.
          Donations made through the platform are non-refundable. All content on
          the platform is protected by intellectual property laws and may not be
          used without permission. Your use of the platform is subject to our
          Privacy Policy
        </p>
      </section>
      <section>
        <br />
        <p className="text-justify">
          <span className="font-bold">Acceptance of Terms:</span> : The platform
          may contain links to third-party websites or services, which we do not
          control and are not responsible for. The platform is provided "as-is"
          without any warranties. Ofu K'Idoma is not liable for any indirect,
          incidental, or consequential damages related to your use of the
          platform. You agree to indemnify Ofu K'Idoma against any claims or
          losses arising from your use of the platform. We reserve the right to
          modify these Terms at any time. Your continued use of the platform
          constitutes acceptance of any changes. These Terms are governed by
          Nigerian law
        </p>

      </section>
      <div className="flex justify-center gap-6 py-10">
        <Link to={`/signup?termStatus=${"agree"}`} className="fp">
          <Button label={"Accept"} />{" "}
        </Link>
        <Link to="/" className="fp">
          <Button label={"Decline"} />
        </Link>
      </div>
    </div>
  );
};

export default Terms;
