import React, { useState, useEffect, useContext } from "react";
import CampaingsNav from "./Campaings-nav";
import "./campaigns.css";
import Navbar from "./topNavbar";
import CampaignTop from "./campaignsTop";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Modal/Loading";
import { checkInternetConnection } from "../../lib/network";
import { AuthContext } from "../../context/AuthContext";
import PopupModal from "../Modal/PopupModal";
import { getAllCampaigns, getCampaignByStatus } from "../../lib/fetch";
import Skeleton from "react-loading-skeleton";
import constants from "../../lib/config";
import DonateModal from "./DonateModal";
import WithdrawModal from "./WithdrawModal";
import Swal from "sweetalert2";
const apiUrl = constants.apiUrl;

const CampaignPage = () => {
  const currentPageName = "Campaigns";
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [isRecurring, setIsRecurring] = useState(true);
  const [email, setEmail] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [TotalDonation, setTotalDonation] = useState(0);
  const [campaignId, setCampaignId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [dataName, setDataName] = useState("All Campaigns");
  const [condition, setCondition] = useState("");
  const [openDonateModal, setOpenDonateModal] = useState(false);
  const [isAnonymousDonor, setIsAnonymousDonor] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState();

  const authCtx = useContext(AuthContext);
  const [status, setStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [campaignsPerPage] = useState(9);
  const [remainingDays, setRemainingDays] = useState(0);
  const [remainingDaysList, setRemainingDaysList] = useState([]);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);

  function truncateText(text, maxLength) {
    const words = text.split(" ");
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(" ") + "...";
    } else {
      return text;
    }
  }

  const handleShowPopup = (message, type) => {
    setMessage(message);
    setType(type);
    setShowPopup(true);
  };

  const handleConditionInput = (data) => {
    setIsLoading(true);
    setCondition(data);
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const isConnected = await checkInternetConnection();
        if (!isConnected) {
          setIsLoading(false);
          handleShowPopup("INTERNET DISCONNECTED", "error");
          setTimeout(() => setShowPopup(false), 3000);
          return;
        }

        let response = await getAllCampaigns(authCtx.token);

        if (condition) {
          setIsLoading(true);
          response = await getCampaignByStatus(condition, authCtx.token);
          console.log(response);
        }
        if (condition === "") {
          response = await getAllCampaigns(authCtx.token);
          console.log(response);
        }
        console.log(condition);
        if (response.success === true) {
          const data = await response.data;

          data.forEach((campaign, index) => {
            campaign.index = index;
          });

          data.sort((a, b) => b.index - a.index);
          setCampaignData(data);

          setTotalDonation(data.amountDonated);
          setTimeout(() => setIsLoading(false), 3000);
          handleFilterChange(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.error("Failed to fetch campaign data");
          alert("Failed to fetch campaign data");
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCampaignData();
  }, [authCtx.token, condition]);

  let navigate = useNavigate();

  const handleCreate = async () => {
    navigate("/create");
  };

  const [filteredCampaigns, setFilteredCampaigns] = useState(campaignData);

  const handleFilterChange = (filteredData) => {
    setFilteredCampaigns(filteredData);
  };
  const handleType = (option) => {
    setDataName(option);
    console.log(option);
  };

  useEffect(() => {
    // Calculate the remaining days for all campaigns
    const calculateRemainingDays = () => {
      const currentDate = new Date();

      const updatedCampaignData = campaignData.map((campaign) => {
        const endDate = new Date(campaign.endDate);
        const timeDifference = endDate - currentDate;
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );
        return {
          ...campaign,
          remainingDays: daysDifference,
        };
      });

      setRemainingDaysList(updatedCampaignData);
    };

    if (campaignData.length > 0) {
      calculateRemainingDays();
    }
    console.log(campaignData);
  }, [campaignData]);

  const handleDonate = async () => {
    try {
      let donationData;
      let response;
      if (authCtx.userInfo.name) {
        if (isAnonymousDonor) {
          // If donating as anonymous, replace user's name with 'Anonymous'
          donationData = {
            amount: selectedAmount,
            userId: "64c79e6ed3277b136a962a46",
            email: "anonymous@example.com",
          };
          response = await fetch(
            `${apiUrl}/api/v1/donation/postdonations/${campaignId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authCtx.token}`,
              },
              body: JSON.stringify(donationData),
            }
          );
          localStorage.setItem("isAnonymousDonor", isAnonymousDonor);
        } else {
          donationData = {
            amount: selectedAmount,
            userId: authCtx.id,
            email: authCtx.userInfo.email,
          };
          response = await fetch(
            `${apiUrl}/api/v1/donation/postdonations/${campaignId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authCtx.token}`,
              },
              body: JSON.stringify(donationData),
            }
          );
        }
      } else {
        donationData = {
          amount: selectedAmount,
          email: email,
        };
        response = await fetch(
          `${apiUrl}/api/v1/donation/visitordonation/${campaignId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(donationData),
          }
        );
        localStorage.setItem("visitor", true);
      }

      const data = await response.json();

      if (data.success) {
        const reference = data.data.reference;

        // Redirect to the authorizationUrl
        window.location.href = data.data.authorizationUrl;

        // Save the reference to local storage or a state variable to access it after the user returns
        localStorage.setItem("paymentReference", reference);
        // localStorage.setItem('paymentUrl', data.data.authorizationUrl)
      } else {
        console.error("Error creating payment:", data.error);
      }
    } catch (error) {
      console.error("Error handling donation:", error);
    }
  };

  const verifyPayment = async (reference) => {
    try {
      const verificationData = {
        reference: reference,
      };

      const response = await fetch(`${apiUrl}/api/v1/donation/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authCtx.token}`,
        },
        body: JSON.stringify(verificationData),
      });

      const data = await response.json();
      // You can handle the verification response here
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: "Your Payment was successfully",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
        //         setTimeout(() => setShowPopup(false), 2000)
        //  setTimeout(() => window.location.reload(), 2000)
        // Payment verified, perform necessary actions
      } else {
        // Payment verification failed, handle accordingly
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      Swal.fire({
        title: "Error",
        text: "Error verifying payment",
        icon: "error",
      });
    } finally {
      setTimeout(() => setShowPopup(false), 2000);
    }
  };
  const verifyVistorPayment = async (reference) => {
    try {
      const verificationData = {
        reference: reference,
      };
      const response = await fetch(`${apiUrl}/api/v1/donation/verify/visitor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(verificationData),
      });

      const data = await response.json();
      // You can handle the verification response here
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: "Your payment was succesful as a vistor",
          icon: "success",
        });
        // Payment verified, perform necessary actions
      } else {
        // Payment verification failed, handle accordingly
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      Swal.fire({
        title: "Error",
        text: "Error verifying payment",
        icon: "error",
      });
    } finally {
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  const handleToggle = () => {
    setIsRecurring(!isRecurring);
  };

  const handleAmountSelection = (amount) => {
    setSelectedAmount(amount);
  };

  const handleDonateModal = (campaignId) => {
    setOpenDonateModal(true);
    setCampaignId(campaignId); // Set the campaignId state
  };

  const handleFrequencyChange = (time) => {
    setSelectedFrequency(time);
  };

  useEffect(() => {
    // Check if the paymentReference exists in localStorage
    const paymentReference = localStorage.getItem("paymentReference");
    const isAnonymousDonor = localStorage.getItem("isAnonymousDonor");
    const visitor = localStorage.getItem("visitor");

    if (!visitor && paymentReference) {
      // Payment reference exists, verify the payment
      verifyPayment(paymentReference);
      // Remove the paymentReference from localStorage after verifying the payment
      localStorage.removeItem("paymentReference");
    } else if (visitor && paymentReference) {
      verifyVistorPayment(paymentReference);
      localStorage.removeItem("paymentReference");
      localStorage.removeItem("visitor");
    }
  }, []);

  if (isLoading) {
    return (
      <div>
        <Navbar
          style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 99 }}
          currentPage={currentPageName}
        />
        <div className="flex flex-col bg-gray-200 md:flex-row mt-5">
          <CampaingsNav className="md:w-1/4 md:mr-0" />
          <div className="flex-1 pr-5 mx-auto px-2 py-4">
            <div className="flex justify-between items-center mb-4">
              {/* <h2 className='font-bold'>Campaigns</h2> */}
              {authCtx.userInfo.userType === "Sponsor" && (
                <button
                  className={` px-6 w-45 py-2 text-white main-bg rounded-full focus:outline-none focus:ring focus:ring-blue-400 mr-4`}
                  onClick={handleCreate}
                >
                  Start a Campaign +
                </button>
              )}
            </div>
            <CampaignTop
              campaigns={[]}
              onFilterChange={handleFilterChange}
              onHandle={handleType}
              condition={condition}
              onHandleCondition={handleConditionInput}
              className="fixed top-0 w-full z-10"
            />
            <br />
            <div className="bg-white p-4 md:mr-1">
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {[...Array(9)].map((_, index) => (
                  <div
                    key={index}
                    className="bg-white c-card rounded-lg shadow-md p-4"
                  >
                    <Skeleton height={200} />
                    <h2 className="text-xl font-bold text-mid my-2">
                      <Skeleton />
                    </h2>
                    <p className="text-gray-700 text-sm mb-4">
                      <Skeleton count={2} />
                    </p>
                    <p className="text-gray-600 text-xs mb-2">
                      <Skeleton width={80} />
                    </p>
                    <div className="bg-gray-200 rounded-lg h-2 mb-4 progress">
                      <Skeleton height={8} />
                    </div>
                    <div className="flex justify-between">
                      <div className="text-sm">
                        <span className="cap-amt text-mid amm">
                          <Skeleton width={60} />
                        </span>
                        <br />
                        <span className="text-red-500">Raised</span>
                      </div>
                      <div className="text-right">
                        <span className="cap-amt text-right amm">
                          <Skeleton width={60} />
                        </span>
                        <br />
                        <span className="text-green-600 font-bold text-right">
                          Goal
                        </span>
                      </div>
                    </div>
                    <div className="cap-btn text-sm font-bold py-2 px-4 rounded-full mt-4 w-full">
                      <Skeleton />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showPopup) {
    return (
      <PopupModal
        status={type}
        title={type === "success" ? "Success" : "Error"}
        message={type === "error" ? message : message}
        onClick={() => setShowPopup(false)}
      />
    );
  }
  const indexOfLastCampaign = currentPage * campaignsPerPage;
  const indexOfFirstCampaign = indexOfLastCampaign - campaignsPerPage;
  const currentCampaigns = filteredCampaigns.slice(
    indexOfFirstCampaign,
    indexOfLastCampaign
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div>
        <Navbar
          style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 99 }}
        />
        <div
          style={{ height: filteredCampaigns.length >= 1 ? "" : "100vh" }}
          className="flex flex-col bg-gray-200 md:flex-row mt-5"
        >
          <CampaingsNav className="md:w-1/4 md:mr-0" />
          <div className="flex-1 pr-5 mx-auto px-2 py-4">
            <div className="flex justify-between items-center md:mb-4">
              {/* <h2 className='font-bold'>Campaigns</h2> */}
              {authCtx.userInfo.userType === "Sponsor" && (
                <button
                  className={` px-6 w-45 py-2 text-white main-bg rounded-full focus:outline-none focus:ring focus:ring-blue-400 mr-4`}
                  onClick={handleCreate}
                >
                  Start a Campaign +
                </button>
              )}
            </div>
            <CampaignTop
              campaigns={campaignData}
              onFilterChange={handleFilterChange}
              onHandle={handleType}
              condition={condition}
              onHandleCondition={handleConditionInput}
              className="fixed top-0 w-full z-10"
            />
            <br />
            <div className="flex items-center justify-between mb-4 w-100">
              {openDonateModal && (
                <DonateModal
                  handleDonate={handleDonate}
                  isAnonymousDonor={isAnonymousDonor}
                  setIsAnonymousDonor={setIsAnonymousDonor}
                  isRecurring={isRecurring}
                  handleFrequencyChange={handleFrequencyChange}
                  selectedFrequency={selectedFrequency}
                  handleToggle={handleToggle}
                  handleAmountSelection={handleAmountSelection}
                  selectedAmount={selectedAmount}
                  setSelectedAmount={setSelectedAmount}
                  email={email}
                  setEmail={setEmail}
                  setOpenDonateModal={setOpenDonateModal}
                />
              )}
              {openWithdrawModal && (
                <WithdrawModal
                  campaignId={campaignData?.data?._id}
                  setOpenWithdrawModal={setOpenWithdrawModal}
                />
              )}

              {/* <Link to='/campaings'>Back</Link> */}
            </div>
            <div className="bg-white p-4 md:mr-1">
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {currentCampaigns.length > 0 ? (
                  currentCampaigns.map((campaign) => (
                    <div
                      style={{ height: "460px" }}
                      key={campaign._id}
                      className="bg-white c-card rounded-lg shadow-md p-4 flex flex-column justify-between"                    >
                      <div className="position-relative">
                        <Link to={`/view?id=${campaign._id}`}>
                          <img
                            src={campaign.imageOrVideo}
                            alt="Campaign"
                            className="w-full c-image object-cover rounded-lg h-40 sm:h-60 md:h-72"
                          />
                        </Link>
                        {/* <p
                          style={{ backgroundColor: 'grey' }}
                          className='position-absolute top-10 end-0 text-white py-2 px-4 rounded-full'
                        >
                          {remainingDays} Days Left
                        </p> */}
                      </div>
                      {/* <Link to={`/view?id=${campaign._id}`}> */}
                      <div className="flex flex-column justify-between h-100">
                        <h4 className="text-[20px] font-bold text-mid mt-2">
                          {truncateText(campaign.title, 3)}
                        </h4>
                        <p className="text-gray-700 text-sm mb-2 max-h-[2.5rem] overflow-hidden line-clamp-2">
                          {truncateText(campaign.description, 20)}
                        </p>

                        <div className="mt-0 md:mt-2">
                          <p className="text-gray-600 text-xs mb-2">
                            {campaign.lastDonationTime}
                          </p>
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            {campaign.sponsor
                              ? campaign.sponsor
                              : campaign?._user?.name}
                          </p>
                        </div>
                        <div className="mt-1">
                          <div
                            className="bg-gray-200 rounded-lg h-2 mb-2 progress"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow={`${
                              (campaign.amountGotten / campaign.raise) * 100
                            }`}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="bg-purple-400 progress-bar rounded-lg"
                              style={{
                                width: `${
                                  (campaign.amountGotten / campaign.raise) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                          <div className="flex justify-between">
                            <div className="text-sm">
                              <span className="cap-amt text-mid amm">
                                ₦{campaign.amountGotten.toLocaleString()}
                              </span>
                              <br />
                              <span className="text-red-500 font-bold">
                                Raised
                              </span>
                            </div>
                            <div className="text-right">
                              <span className="cap-amt text-right amm">
                                ₦{campaign.raise.toLocaleString()}
                              </span>
                              <br />
                              <span className="text-green-600 font-bold text-right">
                                Goal
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <Link to={`/view?id=${campaign._id}`}> */}
                        <button
                          className="cap-btn text-sm font-bold py-2 px-4 rounded-full w-full"
                          onClick={() => handleDonateModal(campaign._id)} // Pass the campaign ID
                        >
                          Donate
                        </button>
                        {/* </Link> */}
                      </div>
                      {/* </Link> */}
                    </div>
                  ))
                ) : (
                  <div
                    style={{ textAlign: "center", width: "100%" }}
                    className="text-center w-100"
                  >
                    No campaign Available
                  </div>
                )}
              </div>
            </div>
            {filteredCampaigns.length > campaignsPerPage && (
              <div className="flex bg-gray-200 justify-end mt-4 mr-5 items-center">
                {/* Previous Button */}
                <button
                  className="pagination-btn"
                  disabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Prev
                </button>

                {/* Page Numbers */}
                <div className="flex space-x-2">
                  {Array.from({
                    length: Math.ceil(
                      filteredCampaigns.length / campaignsPerPage
                    ),
                  }).map((_, index) => (
                    <button
                      key={index}
                      className={`page-link ${
                        index + 1 === currentPage
                          ? "main-bg text-white"
                          : "bg-gray-200 text-gray-700"
                      } w-10 h-10 focus:outline-none`}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>

                {/* Next Button */}
                <button
                  className="pagination-btn ml-2"
                  disabled={
                    currentPage ===
                    Math.ceil(filteredCampaigns.length / campaignsPerPage)
                  }
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;
