import axios from "axios";
import constants from "./config";

const apiUrl = constants.apiUrl;

export const checkInternetConnection = async () => {
  try {
    await axios.get(apiUrl, {
      headers: {
        "Access-Control-Allow-Origin": apiUrl, // replace with your own domain
      },
    });
    console.log(process.env.SERVERS);
    return true; // Internet connection is successful
  } catch (error) {
    return false; // Internet connection failed
  }
};
