import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import CampaignsNav from "../Campaigns/Campaings-nav";
import Navbar from "../Campaigns/topNavbar";
import { AuthContext } from "../../context/AuthContext";
import { getAllCauses, getAllDonations } from "../../lib/fetch";
import PopupModal from "../Modal/PopupModal";
import Loading from "../Modal/Loading";
import { checkInternetConnection } from "../../lib/network";
import Skeleton from "react-loading-skeleton";

const Causes = ({ causeId }) => {
  const [causes, setCauses] = useState([]);
  const [commitedCauses, setCommitedCauses] = useState([]);
  const [activeTab, setActiveTab] = useState("causes"); // 'causes' or 'committedCauses'
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const userId = authCtx.id;

  const handleShowPopup = (message, type) => {
    setMessage(message);
    setType(type);
    setShowPopup(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchCauses = async () => {
      try {
        const isConnected = await checkInternetConnection();
        if (!isConnected) {
          setIsLoading(false);
          handleShowPopup("INTERNET DISCONNECTED", "error");
          setTimeout(() => setShowPopup(false), 3000);
          return;
        }
        const response = await getAllCauses(token);
        const data = await response.data;
        setCauses(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching causes:", error);
        handleShowPopup("Error fetching causes", "error");
        setIsLoading(false);
      } finally {
        setTimeout(() => setShowPopup(false), 3000);
        setIsLoading(false);
      }
    };

    fetchCauses();
  }, [token]);

  useEffect(() => {
    const fetchAllDonations = async () => {
      try {
        const response = await getAllDonations(authCtx.token);
        const donations = response.donations;
        console.log(donations);
        const commitCauses = donations.filter((donation) => {
          return donation?.user?.[0]?._id === authCtx.userInfo._id;
        });

        // Get the causes that the user has committed to
        const userCommittedCauses = donations.filter(
          (donation) =>
            donation?.user?.[0]?._id === authCtx.userInfo._id &&
            donation.case[0] === causeId &&
            donation.isVerified
        );

        console.log(userCommittedCauses);
        setCommitedCauses(userCommittedCauses);
      } catch (error) {
        console.error("Error fetching donations:", error);
      }
    };

    fetchAllDonations();
  }, [authCtx.token, authCtx.userInfo._id, causes]);

  // if (isLoading) {
  //   return <Loading isLoggin={isLoading} color={'white'} />
  // }

  if (!causes) {
    return (
      <PopupModal
        status={"error"}
        message={"No causes found"}
        title={"Failed"}
      />
    );
  }

  if (showPopup) {
    return (
      <PopupModal
        status={type}
        message={message}
        title={type === "success" ? "Successful" : "Failed"}
      />
    );
  }

  const renderCauses = () => {
    if (isLoading) {
      return (
        <div classname="container">
          <div className="bg-white md:w-[900px] rounded-lg mt-10 p-4 md:ml-0 md:mr-0 ml-4 mr-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="border rounded-lg p-4 flex flex-column justify-between"
                >
                  <div>
                    <Skeleton height={40} />
                    <Skeleton count={4} />
                  </div>
                  <div className="flex justify-center">
                    <Skeleton width={180} height={40} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (causes.length === 0) {
      return <div
                // style={{ width: "100%" }}
                className="bg-white md:w-full w-[80%] ml-5 border rounded-lg p-4 text-center "
              >
                {/* <h3 className="text-xl font-bold mb-2 p-2 bg-gray-100">
                  {causes.Name}
                </h3> */}
                <p className="text-gray-600 text-center">No Causes Found</p>
              </div>
              {/* ))} */}
            ;
    }

    return (
      <div classname="container">
        <div className="bg-white md:w-[900px] rounded-lg mt-10 p-4 md:ml-0 md:mr-0 ml-4 mr-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {causes.map((cause) => (
              <div
                key={cause._id}
                className="border rounded-lg p-4 flex flex-column justify-between"
              >
                <div>
                  <h3 className="text-xl text-center font-bold mb-2 p-2 bg-gray-100">
                    {cause.Name}
                  </h3>
                  <p className="text-gray-600 text-justify">
                    {cause.Description}
                  </p>
                </div>
                <Link to={`/cview?id=${cause._id}`}>
                  <div className="flex justify-center">
                    {" "}
                    <button className="main-bg rounded-full text-white font-bold py-2 px-4 mt-4">
                      Donate
                    </button>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderCommittedCauses = () => {
    if (isLoading) {
      return <p>Loading Causes...</p>;
    }

    if (causes.length === 0) {
      return (
        <>
          <div
            // style={{ width: "70vw" }}
            className="bg-white w-[80%] md:w-[70vw] rounded-lg ml-6 md:ml-0 mt-10 p-3 flex"
          >
            {/* <div className='grid grid-cols-3 sm:grid-cols-2 md:grid-cols-2 gap-4'> */}
            <div
              style={{ width: "100%" }}
              className=""
            >
              {/* {causes.map((cause) => ( */}
              <div
                style={{ width: "100%" }}
                key={causes._id}
                className="bg-white border rounded-lg p-4 text-center "
              >
                <h3 className="text-xl font-bold mb-2 p-2 bg-gray-100">
                  {causes.Name}
                </h3>
                <p className="text-gray-600 text-center">No Commited Causes</p>
              </div>
              {/* ))} */}
            </div>
          </div>
        </>
      );
    }

    return (
      <div classname="container">
        <div className="bg-white md:w-[900px] rounded-lg mt-10 p-4 md:ml-0 md:mr-0 ml-4 mr-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {causes.map((cause) => (
              <div
                key={cause._id}
                className="border rounded-lg p-4 flex flex-column justify-between"
              >
                <div>
                  <h3 className="text-xl text-center font-bold mb-2 p-2 bg-gray-100">
                    {cause.Name}
                  </h3>
                  <p className="text-gray-600 text-justify">
                    {cause.Description}
                  </p>
                </div>
                {/* <Link to={`/cview?id=${cause._id}`}>
                  <div className='flex justify-center'>
                    {' '}
                    <button className='main-bg rounded-full text-white font-bold py-2 px-4 mt-4'>
                      Donate
                    </button>
                  </div>
                </Link> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar
        style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 99 }}
      />
      <div
        // style={{ height: causes >= 3 }}
        className="flex flex-col bg-gray-200 h-[100vh] md:flex-row mt-5"
      >
        <CampaignsNav className="md:w-1/4 md:mr-0" />
        <div>
          <div className="flex md:m-0 m-4 sm:mt-0">
            <button
              className={`bg-purple-200 text-black font-bold py-2 px-4 mt-10 mr-2 rounded ${
                activeTab === "causes" ? "bg-purple-200 text-black" : "bg-white"
              }`}
              onClick={() => handleTabChange("causes")}
            >
              Causes
            </button>
            <button
              className={`bg-purple-200 text-purple-500 font-bold py-2 px-4 mt-10 rounded ${
                activeTab === "committedCauses"
                  ? "bg-purple-200 text-black"
                  : "bg-white text-black"
              }`}
              onClick={() => handleTabChange("committedCauses")}
            >
              Committed Causes
            </button>
          </div>
          {activeTab === "causes" ? renderCauses() : renderCommittedCauses()}
        </div>
      </div>
    </div>
  );
};

export default Causes;
